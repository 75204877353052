import { useContext, useEffect, useState } from "react";
import { isMobileContext } from "../../context/isMobileContext";
import CloseButton from "../common/CloseButton";

const PaymentSummary = ({ bookingData }) => {
  const { isMobile } = useContext(isMobileContext);
  const [openItems, setOpenItems] = useState(false);
  const [date, setDate] = useState(false);

  const handleSummary = () => {
    if (openItems) {
      setOpenItems(false);
      document.body.classList.remove("no-scroll");
    } else {
      setOpenItems(true);
      document.body.classList.add("no-scroll");
    }
  };

  useEffect(() => {
    const date = new Date(bookingData?.booking_day);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();
    setDate(
      `${day.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
      })} ${month} ${year}`
    );
  }, [bookingData]);

  return (
    <>
      <div
        className={`summary-container ${isMobile && "mobile"} ${openItems && "opened"
          }`}
      >
        <div className="data-wrapper">
          <div className="booking-summary">
            <h4 className="meduim">Booking Summary</h4>
            <div className="details-container">
              <div className="data">
                <div className="item">
                  <span className="bold">Experience</span>
                  <p className="meduim">{bookingData?.experience_name}</p>
                </div>
                <div className="item">
                  <span className="bold">Date</span>
                  <p className="meduim">{date}</p>
                </div>{" "}
                <div className="item">
                  <span className="bold">Time</span>
                  <p className="meduim">{bookingData?.start_time.start_time}</p>
                </div>
              </div>
              {bookingData?.tickets[0].name == "booking" ? (
                <div className="data users-details">
                  <div className="item extra">
                    <span className="bold">Category</span>
                    <div className="extra-data">
                      <p className="meduim">All</p>
                    </div>
                  </div>
                  <div className="item extra">
                    <span className="bold">Tickets</span>
                    <p className="meduim ">{bookingData?.no_of_ticket}</p>
                  </div>

                  <div className="item extra">
                    <span className="bold">Amount</span>
                    <p className="meduim">{" "}{bookingData?.pricing[0]?.rate}{" AED"}</p>
                  </div>
                </div>
              ) : (
                <div className="data users-details">
                  <div className="item extra">
                    <span className="bold">Category</span>
                    {bookingData?.tickets?.map((member, index) => (
                      <>
                        <div className="extra-data" key={index}>
                          <p className="meduim">{member.name}</p>
                        </div>
                      </>
                    ))}
                  </div>
                  <div className="item extra">
                    <span className="bold">Tickets</span>
                    {bookingData?.tickets?.map((member, index) => (
                      <>
                        {/* <div className="extra-data" key={index}> */}
                        <p className="meduim ">{member.no_of_tickets}</p>
                        {/* </div> */}
                      </>
                    ))}
                  </div>

                  <div className="item extra">
                    <span className="bold">Amount</span>
                    {bookingData?.tickets?.map((member, index) => (
                      <>
                        <p className="meduim">
                          {
                            bookingData?.pricing?.find(
                              pricing => pricing.name === member.name
                            )?.rate * member?.no_of_tickets
                          } {"AED"}
                        </p>
                      </>
                    ))}
                  </div>
                </div>
              )}
              {bookingData?.promo_code && (
                <div className="data">
                  <div className="item promo-code">
                    <p className="bold">Promo code</p>
                    <p className="promo">
                      {bookingData?.promo_code?.promo_name}
                    </p>
                  </div>
                </div>
              )}
              {bookingData?.discount > 0 ?
                <div className="data price">
                  <div className="item promo-code">
                    <p className="bold">Amount after discount</p>
                    <p className="">
                      {(bookingData?.total_amount - bookingData?.discount).toLocaleString("en-US", {
                        minimumIntegerDigits: 2,
                      })}{" "}
                      AED
                    </p>
                  </div>
                  <div className="item promo-code">
                    <p className="bold">Amount before VAT</p>
                    <p className="">
                      {bookingData?.payment_amount_before_tax?.toLocaleString(
                        "en-US",
                        { minimumIntegerDigits: 2 }
                      )}{" "}
                      AED
                    </p>
                  </div>
                  <div className="item promo-code">
                    <p className="bold">VAT Amount</p>
                    <p className="">
                      {bookingData?.payment_tax.toLocaleString("en-US", {
                        minimumIntegerDigits: 2,
                      })}{" "}
                      AED
                    </p>
                  </div>
                </div>
                :
                <div className="data price">
                  <div className="item promo-code">
                    <p className="bold">Amount before VAT</p>
                    <p className="">
                      {bookingData?.payment_amount_before_tax?.toLocaleString(
                        "en-US",
                        { minimumIntegerDigits: 2 }
                      )}{" "}
                      AED
                    </p>
                  </div>
                  <div className="item promo-code">
                    <p className="bold">VAT Amount</p>
                    <p className="">
                      {bookingData?.payment_tax.toLocaleString("en-US", {
                        minimumIntegerDigits: 2,
                      })}{" "}
                      AED
                    </p>
                  </div>
                </div>
              }
              <div className="data price">
                <div className="item promo-code">
                  <p className="bold">Total Amount</p>
                  <p className="">
                    {bookingData?.total_amount.toLocaleString("en-US", {
                      minimumIntegerDigits: 2,
                    })}{" "}
                    AED
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>



        <div className={`footer`}>
          <div className="title">
            <p className="medium">Order Summary</p>
            <h1 className="bold">
              {bookingData?.experience_name?.length > 30 ? `${bookingData?.experience_name?.substring(0, 30)}...` : bookingData?.experience_name}
            </h1>
          </div>
          <div className="total">
            <div className="item price">
              <span className="medium">Total amount</span>
              <p className="bold cap">
                {" "}
                {bookingData?.total_amount.toLocaleString("en-US", {
                  minimumIntegerDigits: 2,
                })}{" "}
                AED
              </p>
            </div>
          </div>
        </div>
        <CloseButton icon onClick={handleSummary} />
      </div>
      {openItems && isMobile && <div className="website-overlay"></div>}
    </>
  );
};

export default PaymentSummary;
