import { API_URL, getTicketsPdf } from "../../../utils/ApiHandler";
import Button from "../../common/Button";

const Confirming = ({ bookingData, id, setOpenCancel }) => {
  return (
    <div className="confirm-booking-container">
      <div className="header">
        <img src="./icons/check-icon.svg" alt="icon" />
        <div className="header-content">
          <h1 className="medium">Booking Confirmed. Thank you!</h1>
          <div className="header-description">

            <span className="medium">
              You will receive a confirmation email soon.
            </span>
            {/* <div
              className="print"
              onClick={() => window.open(`${API_URL}/booking/download/${id}`)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#1661F1"
                  d="m12 16l-5-5l1.4-1.45l2.6 2.6V4h2v8.15l2.6-2.6L17 11l-5 5Zm-6 4q-.825 0-1.413-.588T4 18v-3h2v3h12v-3h2v3q0 .825-.588 1.413T18 20H6Z"
                />
              </svg>
              <span className="medium">Download ticket</span>
            </div> */}
          </div>
        </div>
      </div>
      <div className="booking-details">
        <h2>Booking Details</h2>
        <div className="order-data">
          <div className="item">
            <span className="bold">Experience</span>
            <p className="medium">{bookingData?.experience_name}</p>
          </div>
          <div className="item">
            <span className="bold">Date</span>
            <p className="medium">
              {new Date(bookingData?.booking_day)?.toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
            </p>
          </div>
          {bookingData?.start_time.start_time && (
            <div className="item">
              <span className="bold">Time Slot</span>
              <p className="medium">
                {bookingData?.start_time.start_time} to{" "}
                {bookingData?.start_time.end_time}
              </p>
            </div>
          )}
          <div className="item">
            <span className="bold">order id</span>
            <p className="medium">#{bookingData?.booking_id}</p>
          </div>
          {/* <div className="item">
            <span className="medium">Tickets</span>
            <p className="bold">{bookingData?.no_of_ticket}</p>
          </div>
          <div className="item">
            <span className="medium">Check-in Date</span>
            <p className="bold">{bookingData?.booking_day}</p>
          </div> */}
        </div>
        <div className="order-data">
          {bookingData?.tickets[0].name == "booking" ? (
            <div className="item">
              <span className="bold">Tickets</span>
              <p className="medium">{bookingData?.tickets[0].no_of_tickets} </p>
            </div>
          ) : null}
        </div>
      </div>

      <div className="booking-details">
        <h2>Guest Information</h2>
        <div className="order-data">
          <div className="item">
            <span className="bold">Name</span>
            <p className="medium">
              {bookingData?.participent_info[0]?.contact?.first_name}{" "}
              {bookingData?.participent_info[0]?.contact?.last_name}{" "}
            </p>
          </div>
          <div className="item">
            <span className="bold">Email</span>
            <p className="medium email">
              {bookingData?.participent_info[0]?.contact?.email}{" "}
            </p>
          </div>
          <div className="item">
            <span className="bold">Mobile</span>
            <p className="medium">
              {bookingData?.participent_info[0]?.contact?.phone}{" "}
            </p>
          </div>
        </div>
      </div>
      <div className="booking-details">
        <h2>Payment Details</h2>
        <div className="order-data">
          <div className="item">
            <span className="medium">Payment Method</span>
            <p className="bold">{bookingData?.payment_type}  ****{bookingData?.payment_method_last4}</p>
          </div>
          {/* <div className="item">
            <span className="medium">Check-in Date</span>
            <p className="bold">{bookingData?.booking_day}</p>
          </div> */}
        </div>
      </div>
      <div className="booking-details print-details">
        <Button
          onClick={() => {
            setOpenCancel(true);
            document.body.classList.add("no-scroll");
          }}
          text="Cancel booking"
          customClass="red"
        />
      </div>
    </div>
  );
};

export default Confirming;
