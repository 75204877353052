import './App.scss';
import Layout from './components/Layout';
import {
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import Widget from './components/widget/Widget';
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Success from './pages/Success';
import Payments from './pages/Payments';
import VerifyAppleWallet from './VerifyAppleWallet';

function App() {
  const queryClient = new QueryClient()
  let { search } = useLocation();
  const id = search.split('?id=')[1]
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ToastContainer />
        <Layout>
          <Routes>
            <Route path={``} exact element={<Widget id={id}/>} />
            <Route path={`/success`} exact element={<Success id={id}/>} />
            <Route path={`/payment`} exact element={<Payments id={id}/>} />
            <Route path={`/.well-known/apple-developer-merchantid-domain-association`} exact element={<VerifyAppleWallet/>} />
          </Routes>
        </Layout>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </>
  );
}

export default App;
