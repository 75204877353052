import { Formik, Form, FieldArray, Field } from "formik";
import Button from '../../common/Button'
import * as Yup from "yup";
import { useEffect, useState, useContext } from "react";
import "react-phone-number-input/style.css"
import axios from "axios";
import InfoFormGroup from "./InfoFormGroup";
import { bookingExtras } from "../../../context/bookingExtras";
import { checkPromoCodesExpiry, createBooking } from "../../../utils/ApiHandler";
import { toast } from "react-toastify";

const InfoForm = ({ setTotalAmount,
    setPaymentAmount,
    setSelectedPromoCode,
    setPaymentTax, setClientID, activeStep, setFormData, selectedDay, setActiveStep, experienceData, setBookingData, allMembers, extras, bookingData }) => {
    const [loader, setLoader] = useState(false)
    const [nationalities, setNationalities] = useState([])
    const [languages, setLanguage] = useState([])
    const { bookingExtraIds } = useContext(bookingExtras)
    const [membersForm, setMembersForm] = useState([])
    const [validation, setValidation] = useState()
    const [initial, setInitial] = useState({})
    const [paymentOption, setPaymentOption] = useState('full')
    const [promoCodeValue, setPromoCodeValue] = useState("")
    const [checkingPromo, setCheckingPromo] = useState(false)
    const [validCode, setValidCode] = useState(null)
    const [phoneError, setPhoneError] = useState(false)

    // useEffect(() => {
    //     setLanguage(allLanguages);
    // }, [])

    useEffect(() => {
        fetch('https://restcountries.com/v3.1/all')
            .then(response => response.json())
            .then(data => {
                const allNationalities = []
                data.map((country, index) =>
                // index < 100 &&
                (
                    allNationalities.push({
                        label: country.name.common,
                        value: country.name.common,
                    })
                )

                );
                setNationalities([...allNationalities].sort((a, b) => a.label.localeCompare(b.label)))
            })
            .catch(error => console.log(error));
    }, [])

    const [country, setCountry] = useState()

    async function getCountry() {
        try {
            const response = await axios.get("https://ipapi.co/json/")
            setCountry(response.data.country)
            return response.data.country
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        getCountry()
    }, [])

    const handleSubmit = (formData) => {
        const allMembersData = [...allMembers]
        let filteredData = [];
        const contact_info = [];
        const tickets = []
        allMembersData.map(member => {
            member.categoryNo != 0 &&
                tickets.push({
                    pricing_id: member.categoryID,
                    no_of_tickets: member.categoryNo
                })
        })
        Object.keys(formData).forEach((category, indexx) => {
            filteredData.push(
                membersForm[category].map((item, index) =>
                    formData[category].map((formItem, i) => (
                        ({
                            ...item,
                            is_primary: 0,
                            contact: { ...item.contact, ...formItem }
                        }
                        )
                    ))
                )
            )
        });

        filteredData.flat().map((data, i) => {
            data.map((item, index) => {
                if (experienceData.guest_contact) {
                    contact_info.push(
                        {
                            ...item,
                            extras: (index == 0 && i == 0) ? [...bookingExtraIds] : []
                        }
                    )
                }
                else {
                    index == 0 && i == 0 &&
                        contact_info.push({
                            ...item,
                            tickets: [...tickets],
                            extras: [...bookingExtraIds]
                        })
                }
            })
        });
        const info = contact_info.filter((item, index) => {
            return (
                index ===
                contact_info.findIndex((obj) => {
                    return JSON.stringify(obj) === JSON.stringify(item);
                })
            );
        });
        const contactInfo = info.map((item, index) => (
            {
                ...item,
                is_primary: index == 0 ? 1 : 0
            }
        ))
        if (experienceData?.is_booking_active == 0) {
            toast.warning(`Stripe: Booking disabled for that experience`, {
                position: "top-right",
                className: "toast-message",
                autoClose: 2500,
            })
            setLoader(false)
        } else {
            createBooking({
                ...bookingData,
                payment_option: paymentOption,
                contact_info: [...contactInfo]
            }, setLoader, setActiveStep, activeStep, setClientID, setTotalAmount,
                setPaymentAmount,
                setPaymentTax)
        }
    }

    useEffect(() => {
        var categoryArray;
        const filteredData = allMembers.filter(item => item.categoryNo != 0);

        // Create a new object with the desired values
        const newItem = {
            ...filteredData[0],
            categoryName: "primary",
            categoryNo: 1,
            order: 1
        };
        const firstCategory = {
            ...filteredData[0],
            categoryName: filteredData[0]?.categoryName,
            categoryNo: +filteredData[0]?.categoryNo - 1,
        }
        // Push the new item to the filtered data array
        filteredData.push(newItem);
        filteredData.push(firstCategory);
        filteredData.shift()

        const filterMembers = filteredData.filter(member => member.categoryNo > 0).sort((a, b) => a.order - b.order)
        var results = filterMembers.reduce((acc, category, index) => {
            categoryArray = Array.from({ length: category.categoryNo }, (_, i) => ({
                is_primary: index == 0 ? i == 0 ? 1 : 0 : 0,
                tickets: [{
                    pricing_id: category.categoryID,
                    no_of_tickets: 1
                }],
                contact: {
                    first_name: "",
                    last_name: "",
                    email: "",
                    phone: "",
                    gender: "",
                    title: "",
                    date_of_birth: "",
                    organization: "",
                    address: "",
                    personal_id_number: "",
                    language: "",
                    nationality: "",
                    passport_id: "",
                }
            }));
            acc[category.categoryName] = categoryArray;
            return acc;
        }, {});

        setMembersForm(results)
    }, [allMembers])

    useEffect(() => {
        const errors = {}
        const initialValues = {}
        Object.entries(membersForm).map(([item, array], index) => (
            (
                errors[`${item}`] = Yup.array().of(
                    Yup.object().shape(
                        {
                            email:
                                (item == 'primary' ? experienceData?.personal_contact?.split(',').includes('email') :
                                    experienceData?.guest_contact?.split(',').includes('email'))
                                &&
                                Yup.string()
                                    .email('Wrong')
                                    .required('Required')
                                    .matches(/^([a-zA-Z0-9_\-\.])/, 'Wrong'),
                            phone:
                                (item == 'primary' ? experienceData?.personal_contact?.split(',').includes('phone') :
                                    experienceData?.guest_contact?.split(',').includes('phone')) &&
                                Yup.string()
                                    .required('Required')
                                    .transform((value) => (value ? value.replace(/[\s+]/g, "") : ""), 'Wrong')
                                    .max(15, 'Wrong')
                                    .min(4, 'Wrong'),
                            first_name:
                                (item == 'primary' ? experienceData?.personal_contact?.split(',').includes('first_name') :
                                    experienceData?.guest_contact?.split(',').includes('first_name')) &&
                                Yup.string().required('Required').matches(/^[^ ]\S*/, "Wrong"),
                            last_name:
                                (item == 'primary' ? experienceData?.personal_contact?.split(',').includes('last_name') :
                                    experienceData?.guest_contact?.split(',').includes('last_name'))
                                &&
                                Yup.string().required('Required').matches(/^[^ ]\S*/, "Wrong"),
                            gender:
                                (item == 'primary' ? experienceData?.personal_contact?.split(',').includes('gender') :
                                    experienceData?.guest_contact?.split(',').includes('gender'))
                                &&
                                Yup.string().required('Required'),
                            title:
                                (item == 'primary' ? experienceData?.personal_contact?.split(',').includes('title') :
                                    experienceData?.guest_contact?.split(',').includes('title'))
                                &&
                                Yup.string().required('Required'),

                            date_of_birth:
                                (item == 'primary' ? experienceData?.personal_contact?.split(',').includes('date_of_birth') :
                                    experienceData?.guest_contact?.split(',').includes('date_of_birth'))
                                &&
                                Yup.date().max(new Date(), "Please enter valid date").required('Required'),

                            organization:
                                (item == 'primary' ? experienceData?.personal_contact?.split(',').includes('organization') :
                                    experienceData?.guest_contact?.split(',').includes('organization'))
                                &&
                                Yup.string().required('Required').matches(/^[^ ]\S*/, "Wrong"),
                            address:
                                (item == 'primary' ? experienceData?.personal_contact?.split(',').includes('address') :
                                    experienceData?.guest_contact?.split(',').includes('address'))
                                &&
                                Yup.string().required('Required').matches(/^[^ ]\S*/, "Wrong"),
                            passport_id:
                                (item == 'primary' ? experienceData?.personal_contact?.split(',').includes('passport_id') :
                                    experienceData?.guest_contact?.split(',').includes('passport_id'))
                                &&
                                Yup.string().required('Required').matches(/^[^ ]\S*/, "Wrong"),
                            personal_id_number:
                                (item == 'primary' ? experienceData?.personal_contact?.split(',').includes('personal_id_number') :
                                    experienceData?.guest_contact?.split(',').includes('personal_id_number'))
                                &&
                                Yup.string()
                                    .required('Required')
                                    .max(11, 'Wrong')
                                    .min(10, 'Wrong'),
                            language:
                                (item == 'primary' ? experienceData?.personal_contact?.split(',').includes('language') :
                                    experienceData?.guest_contact?.split(',').includes('language'))
                                &&
                                Yup.string().required('Required'),
                            nationality:
                                (item == 'primary' ? experienceData?.personal_contact?.split(',').includes('nationality') :
                                    experienceData?.guest_contact?.split(',').includes('nationality'))
                                &&
                                Yup.string().required('Required'),
                        }
                    )
                ),
                initialValues[`${item}`] = array.map(item => ({
                    first_name: "",
                    last_name: "",
                    email: "",
                    phone: "",
                    gender: "",
                    title: "",
                    date_of_birth: "",
                    organization: "",
                    address: "",
                    personal_id_number: "",
                    language: "",
                    nationality: "",
                    passport_id: ""

                }))
            )
        ))
        setValidation(errors)
        setInitial(initialValues)

    }, [membersForm, experienceData])

    const checkPromoCode = () => {
        setCheckingPromo(true)
        checkPromoCodesExpiry(promoCodeValue, bookingData?.booking_day).then(data => {
            if (data.data.data) {
                setCheckingPromo(false)
                setValidCode(true)
                setSelectedPromoCode(data.data.data)
                setBookingData(current => ({
                    ...current,
                    promo_code: data?.data?.data?.code
                }))
            } else {
                setCheckingPromo(false)
                setValidCode(false)
                setSelectedPromoCode(null)
                setBookingData(current => ({
                    ...current,
                    promo_code: null
                }))
            }
        }).catch(error => {
            setCheckingPromo(false)
            setValidCode(false)
            setSelectedPromoCode(null)
            setBookingData(current => ({
                ...current,
                promo_code: null
            }))
        })
    }
    return (
        <div className="form-container">
            {selectedDay?.discount?.length == 0 &&
                < div className="promo-code-container">
                    <h4 className="medium">Do you have promo code ?</h4>
                    <div className="code-input">
                        <input placeholder="Enter promo code"
                            value={promoCodeValue}
                            onChange={(e) => setPromoCodeValue(e.target.value)}
                        />
                        {promoCodeValue && <div className={`${checkingPromo && "check"}  apply bold`} onClick={checkPromoCode}>Apply</div>}
                    </div>
                    {validCode != null &&

                        <div className={`${!validCode && "error"} code-validation `} style={{ cursor: 'unset' }}>
                            {validCode ? 'Valid code' : "Invalid code"}
                        </div>
                    }
                </div>
            }
            <h4 className="medium">Contacts Information</h4>
            {
                Object.keys(initial).length != 0 ?
                    <Formik
                        enableReinitialize
                        initialValues={initial}
                        validationSchema={Yup.object(validation)}
                        onSubmit={(values, { resetForm, setFieldValue }) => {
                            if (!phoneError) {
                                setFormData(values)
                                setLoader(true)
                                handleSubmit(values)
                            }
                        }}
                    >
                        {(formik) => (
                            <Form>
                                {
                                    Object.entries(membersForm).map(([categoryName, categoryArray], i) => (
                                        <FieldArray
                                            key={i}
                                            name={`${categoryName}`}
                                            render={() => (
                                                <>
                                                    {categoryArray.map((res, index) => (
                                                        experienceData?.guest_contact ?

                                                            <div key={index}>
                                                                <h4 className="form-header">{categoryName} {categoryName == 'primary' && "contact"}</h4>
                                                                <InfoFormGroup
                                                                    index={index}
                                                                    key={index + 'primary_info'}
                                                                    formik={formik}
                                                                    country={country}
                                                                    value={`${categoryName}`}
                                                                    errors={formik?.errors.categoryName}
                                                                    touched={formik?.touched.categoryName}
                                                                    condition={res.is_primary == 1 ? experienceData?.personal_contact?.split(',') : experienceData?.guest_contact?.split(',')}
                                                                    nationalities={nationalities}
                                                                    languages={languages}
                                                                    phoneError={phoneError}
                                                                    setPhoneError={setPhoneError}
                                                                />
                                                            </div> :
                                                            index == 0 &&
                                                            i == 0 &&
                                                            <div key={index}>
                                                                <InfoFormGroup
                                                                    index={index}
                                                                    key={index + 'primary_info'}
                                                                    formik={formik}
                                                                    country={country}
                                                                    value={`${categoryName}`}
                                                                    errors={formik?.errors.categoryName}
                                                                    touched={formik?.touched.categoryName}
                                                                    condition={experienceData?.personal_contact?.split(',')}
                                                                    nationalities={nationalities}
                                                                    languages={languages}
                                                                    phoneError={phoneError}
                                                                    setPhoneError={setPhoneError}
                                                                />
                                                            </div>
                                                    ))}
                                                </>

                                            )}
                                        />
                                    ))
                                }
                                <div className="form-group button-group">
                                    <Button text={'Previous'} type={'button'} onClick={() => { setActiveStep(activeStep - 1) }} />
                                    <Button text={'Proceed to payment'} type={(loader || checkingPromo) ? 'button' : 'submit'} disabled={loader || checkingPromo} />
                                </div>
                            </Form>
                        )}
                    </Formik> : ""
            }
        </div >
    );
}

export default InfoForm;